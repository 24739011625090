#home {

  background: url("../../assets/bgIMG.png");
  background-position: center;
  background-size: cover;
}

.header {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  @media screen and (min-width:2000px) {
    margin-top: 4rem;
  }
}

.profile {
  position: relative;
  width: 750px;
  height: 750px;

  @media screen and (max-width: 1000px) {
    width: 650px;
    height: 650px;
  }

  @media screen and (max-width: 875px) {
    width: 450px;
    height: 450px;
  }

  @media screen and (max-width: 675px) {
    width: 400px;
    height: 400px;
  }

  @media screen and (max-width: 440px) {
    width: 300px;
    height: 300px;
  }
}

.profile_circle {
  
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
  opacity: .5;
  border-radius: 50%;
}

.profile_image {
  display: flex;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.529);
border: 1px solid rgba(0, 0, 0, 0.36);
}
