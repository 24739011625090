.app__experience {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.experience_container {
  width: 80%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.experience {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.experience_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
}

.experience_year {
  display: flex;
  height: 100%;
  justify-content: flex-start;

  p {
    text-align: center;
    align-content: center;
    margin: 1rem;
    // font-size: 35px;
    font-weight: 800;
    color: var(--secondary-color);
  }
}

.experience_works {
  flex: 1;

  .experience_work {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;

    h4 {
      font-weight: 400;
    }

    p {
      font-weight: 300;
      color: var(--gray-color);
      margin-top: 5px;
    }
  }

  .work-title {
    display: flex;
    font-size: 50px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    transition: transform 0.3s ease;

    &:hover {
      text-shadow: 0 0 15px rgba(0, 13, 255, 0.767);
      transform: scale(1.3);
    }

    @media screen and (min-width: 2000px) {
      font-size: 45px;
    }

    @media screen and (max-width: 2000px) {
      font-size: 40px;
    }

    @media screen and (max-width: 1680px) {
      font-size: 35px;
    }

    @media screen and (max-width: 1500px) {
      font-size: 30px;
    }

    @media screen and (max-width: 1300px) {
      font-size: 25px;
    }

    @media screen and (max-width: 1150px) {
      font-size: 20px;
    }

    @media screen and (max-width: 990px) {
      font-size: 15px;
    }

  }

  .work-company {
    @media screen and (min-width: 2000px) {
      // font-size: 85px;
    }
  }

  .work-description {
    @media screen and (min-width: 2000px) {
      font-size: 40px;
    }

    @media screen and (max-width: 850px) {
      font-size: 15px;
    }
  }
}