.app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 0 2rem;
  
    .app__work-filter-item {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      color: #000;
      font-weight: 800;
      cursor: pointer;
      transition: all 0.5s ease;
      margin: 0.5rem;
  
      &:hover {
        background-color: var(--secondary-color);
        color: #ffffff;
      }
  
      @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
      }
    }
  
    .item-active {
      background-color: var(--secondary-color);
      color: #fff;
    }
  }
  
  .app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
  
    .app__work-item {
      width: 270px;
      height: 500px;
      flex-direction: column;
  
      margin: 2rem;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      color: #000;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0ch 0px 30px rgba(0, 0, 0, 0.385);
      &:hover {
        box-shadow: 0ch 0px 30px rgba(74, 98, 255, 0.605);
      }
  
      @media screen and (min-width: 2000px) {
        width: 470px;
        height: 850px;
        padding: 1.25rem;
        border-radius: 0.75rem;
      }

      @media screen and (max-width: 850px) {
        width: 100%;  /* Ensure the item takes full width */
        margin: 1rem; /* Adjusted margin for mobile */
        padding: 0.5rem; /* Adjusted padding for mobile */
        :hover{
          display: flex;
        }
      
      }
  
      @media screen and (max-width: 300px) {
        width: 100%;
        margin: 1rem;
        opacity: 1; /* Always show hover effect */
      }
    }
  }
  
  .app__work-img {
    width: 100%;
    height: 230px;
    position: relative;
  
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
      height: 350px;
    }

    @media screen and (max-width: 850px) {
      height: 350px;
      width:auto;
    }

    @media screen and (max-width: 600px) {
      height: 300px;
      width:auto;
    }
  }
  
  .app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.269);
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
  
      margin: 1rem;
      font-family: var(--font-base);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
  
      svg {
        width: 50%;
        height: 50%;
        // color: var(--white-color);
        // color: rgb(255, 0, 200);
      }
    }
    @media screen and (max-width:850px){
      opacity: 1;
      background-color: rgba(0, 0, 0, 0);
    }
  }
  
  .app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  
    h4 {
      margin-top: 1rem;
      line-height: 1.5;
  
      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }
  
    .app__work-tag {
      position: absolute;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      background-color: #fff;
      top: -25px;
    }
  }